<template>
  <NuxtLayout :name="$isSol ? 'charter-page' : 'banner-default'">
    <div class="w-12/12 mt-6 mb-12 md:mt-5 pb-12 pt-2">
      <div
        class="text-[2.5rem]"
        data-i18n="solresor-app-webpage-title"
      >
        {{ $t('solresor-app-webpage-title') }}
      </div>
      <p
        class="my-4"
        data-i18n="solresor-app-webpage-text"
      >
        {{ $t('solresor-app-webpage-text') }}
      </p>
      <a
        class="badge"
        :href="appStoreAppUri"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="/logos/apple.webp"
          alt="Download on the App Store"
        />
      </a>
      <a
        class="badge"
        :href="playAppUri"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="/logos/google.webp"
          alt="Get it on Google Play"
        />
      </a>
    </div>
    <div
      v-if="$isSol"
      class="full-width bg-light-turquoise"
    >
      <div class="container mx-auto">
        <div class="pb-12 mb-12 charter-start">
          <div class="py-6 md:py-5 mb-12">
            <div class="w-full px-4 layout-container mx-auto pb-6 pt-4 md:pt-0">
              <HydrateWhenVisible class="min-h-[600px] md:min-h-[450px]">
                <LazyCharterDestinations />
              </HydrateWhenVisible>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <LazyHomeTripList />
    </div>
  </NuxtLayout>
</template>

<script setup>
const { t } = useI18n()
const rootStore = useRootStore()
const charterPackagesStore = useCharterPackagesStore()

const tripStore = useTripStore()

const {
  appMeta,
  redirectToAppStore,
  playAppUri,
  appStoreAppUri,
} = useApp()

rootStore.SET_PAGE_BANNER({
  title: appMeta.value.title,
  type: 'small',
})

const { $isSol } = useNuxtApp()

await useAsyncData(async () => {
  if ($isSol) {
    await charterPackagesStore.getDestinations()
  } else {
    await tripStore.fetchCategoryStart()
  }

  return {}
})

useHead({
  title: () =>  appMeta.value.title,
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: () => t('solresor-app-webpage-title'),
    },
  ],
})

onMounted(() => {
  redirectToAppStore()
})
</script>

<style lang="scss" scoped>
.badge {
  img {
    width: 100%;
    max-width: 150px;
  }
}
</style>